import { compositionFunction as CallToActionComposition } from 'src/components/Common/@electron/CallToAction/composition';
import Composition from 'src/lib/HOC/Composition';
import data from './data';
import { Accordion } from './index';

const { component, compositionFunction } = Composition(Accordion)<typeof data>(props => {
  return {
    ...CallToActionComposition(props),
    items: props.fields?.items?.map(({ fields, id }) => ({
      id,
      title: fields.Title,
      text: fields.Content,
      analytics: {
        action: fields.Title?.value,
        category: 'accordion',
        label: props.fields?.Headline?.value,
        guid: id,
      },
    })),
  };
});

export { compositionFunction, component as default };
